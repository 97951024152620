<template>
  <div class="h-100 d-flex flex-column position-relative skata">
    <div class="input-group position-absolute zindex-1 map-input-container">
      <GmapAutocomplete @place_changed="setPlace" class="form-control shadow">
      </GmapAutocomplete>
    </div>
    <GmapMap
      :options="mapOptions"
      :center="mapCenter"
      :zoom="mapZoom"
      map-type-id="terrain"
      class="map border border-secondary"
      id="casesMap"
      ref="casesMap"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="showCase(index)"
      />
    </GmapMap>
    <b-modal centered ref="case-modal" hide-footer title="Λεπτομέριες Υπόθεσης">
      <div class="d-block text-center">
        <div class="text-h4">{{ modalText }}</div>
      </div>
      <div class="separator separator-solid my-3"></div>
      <div class="row">
        <div class="col-md-6">
          <b-button variant="success" block @click="buyCase">
            Αγορά
          </b-button>
        </div>
        <div class="col-md-6">
          <b-button variant="outline-secondary" block @click="hideModal">
            Άκυρο
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
// Override theme layout
#kt_content_cases {
  padding: 0 !important;
  .container {
    padding: 0 !important;
    max-width: none !important;
  }
}

.map {
  width: 100%;
  height: 100%;
}

.map-input-container {
  top: 1em;
  right: 0.5em;
  max-width: 400px;
}
</style>

<script>
import { gmapApi } from "vue2-google-maps";
import { GET_MAP_STYLES } from "@/core/services/store/maps.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "cases",
  comments: {},
  data() {
    return {
      mapId: "9282da73e51ba1ad",
      mapZoom: 6,
      mapCenter: { lat: 39.074208, lng: 21.824312 },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        draggable: true
      },
      markers: [],
      options: [
        { value: "1", text: "Αθήνα" },
        { value: "2", text: "Θεσσαλονίκη" }
      ],
      item: {
        value: "",
        text: ""
      },
      modalText: "",
      selectedCaseId: null
    };
  },
  beforeMount() {
    if (this.$store.getters.mapStyles.length === 0) {
      this.$store
        .dispatch(GET_MAP_STYLES)
        .then(data => {
          this.mapOptions.styles = data;
        })
        .catch(() => {});
    } else {
      this.mapOptions.styles = this.$store.getters.mapStyles;
    }
  },
  mounted() {
    ApiService.setHeader();
    ApiService.get("assignment?limit=50")
      .then(({ data }) => {
        data.data.forEach(value => {
          this.markers.push({
            details: value.residence_type,
            id: value.id,
            position: {
              lat: value.latitude,
              lng: value.longitude
            }
          });
        });
      })
      .catch(() => {});
  },
  methods: {
    showCase(index) {
      this.modalText = this.markers[index].details;
      this.selectedCaseId = this.markers[index].id;
      this.showModal();
    },
    reset() {
      this.item = {};
    },
    selectFromParentComponent1() {
      // select option from parent component
      this.item = this.options[0];
    },
    buyCase() {
      if (this.selectedCaseId !== null) {
        this.$router.push({
          name: "checkout",
          params: { caseId: this.selectedCaseId }
        });
      }
    },
    showModal() {
      this.$refs["case-modal"].show();
    },
    hideModal() {
      this.$refs["case-modal"].hide();
      this.modalText = "";
      this.selectedCaseId = null;
    },
    setDescription(description) {
      this.description = description;
    },
    setPlace(place) {
      if ("geometry" in place) {
        this.$refs.casesMap.$mapPromise.then(map => {
          map.fitBounds(place.geometry.viewport);
          map.panToBounds(place.geometry.viewport);
        });
      } else {
        this.$refs.casesMap.$mapPromise.then(map => {
          map.panTo(this.mapCenter);
          map.setZoom(7);
        });
      }
    }
  },
  computed: {
    google: gmapApi
  }
};
</script>
